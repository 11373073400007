/**
 * Change the look and feel of CKEditor features.
 *
 * DO NOT apply styles here that affect the content rendered by
 * the editor - those should be added to reset.css or injected.css.
 */

/* === Editor styles === */

.msend-ckeditor .ck.ck-editor {
  --ck-border-radius: 4px;
  --ck-color-mention-background: #006dae26;
  --ck-color-mention-text: #004e7d;
}

.msend-ckeditor .ck.ck-editor__main {
  --ck-color-base-border: transparent;
  --ck-color-focus-border: #a6cef0;
}

.msend-ckeditor .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.msend-ckeditor .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.msend-ckeditor .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners,
.msend-ckeditor .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-bottom-left-radius: var(--ck-border-radius);
  border-bottom-right-radius: var(--ck-border-radius);
  border-top-left-radius: var(--ck-border-radius);
  border-top-right-radius: var(--ck-border-radius);
  border-bottom-width: 1px;
}

.msend-ckeditor .ck.ck-toolbar {
  margin-bottom: 4px;
}

.msend-ckeditor .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  box-shadow: none;
}

/* === Editing table styles === */

.msend-ckeditor
  .ck-widget.table
  td.ck-editor__nested-editable.ck-editor__nested-editable_focused,
.msend-ckeditor .ck-widget.table td.ck-editor__nested-editable:focus,
.msend-ckeditor
  .ck-widget.table
  th.ck-editor__nested-editable.ck-editor__nested-editable_focused,
.msend-ckeditor .ck-widget.table th.ck-editor__nested-editable:focus {
  outline: none;
  border: 1px solid #555555; /* based off default table style in injected.css */
}

/* === Mention (token) styles === */

.msend-ckeditor .ck.ck-content .mention {
  padding: 3px;
  border-radius: 5px;
  transition: background-color 0.1s;
}

.msend-ckeditor .ck.ck-content .mention:hover {
  --ck-color-mention-background: #0a83c935;
}

/* === Hiding the toolbar === */

.msend-ckeditor.hidden .ck.ck-toolbar {
  display: none;
}
