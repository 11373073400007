/**
 * Reset styles applied by CKEditor to look like default styles.
 * 
 * DO NOT apply styles here that would make things look different
 * from the defaults applied by the browser. Otherwise, the preview
 * feature will not give an accurate preview as these styles are not
 * present when the email is sent.
 *
 * To add styling to the email, the CSS must be present in the
 * template itself.
 */

.msend-ckeditor .table table th,
.msend-ckeditor .ck-editor .table table th {
  background: revert;
}

.msend-ckeditor .table {
  margin: revert;
}

.msend-ckeditor .table table {
  border-collapse: revert;
  border-spacing: revert;
}

.msend-ckeditor .table :is(table, table td, table th) {
  padding: revert;
  border: revert;
}

.msend-ckeditor hr {
  background: revert;
  border: revert;
  height: revert;
  margin: revert;
}
