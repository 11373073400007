@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

pre,
code {
  font-family: "Roboto Mono", monospace;
}

.firebase-emulator-warning {
  border: none !important;
  background: #ffffff69 !important;
}
